import "./App.css";
import GameList from "./components/GameList";
import GameComponent from "./components/GameComponent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
function App() {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <header className="App-header">
          <Routes>
            <Route path="/" element={<GameList />} />
            <Route path="/game/prompt" element={<GameComponent />} />
            <Route path="/game/rhyme" element={<GameList />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
