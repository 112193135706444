import React, { useState } from "react";
import axios from "axios";
import config from "../config"; // adjust the path according to your directory structure
import { Container, Row, Col, Button, FormControl } from "react-bootstrap";

function GameComponent() {
  const [gameId, setGameId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [score, setScore] = useState(null);
  const [message, setMessage] = useState("");
  const [gameStarted, setGameStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const startGame = async () => {
    setIsLoading(true);
    setGameStarted(true);
    const response = await axios.post(`${config.apiBaseURL}/api/game/start`);
    setGameId(response.data.gameId);
    setImageUrl(response.data.imageUrl);
    setIsLoading(false);
  };

  const submitAnswer = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); 
    const response = await axios.post(`${config.apiBaseURL}/api/game/finish`, {
      gameId: gameId,
      guess: userInput,
    });
    setScore(response.data.score);
    setMessage(response.data.message);
    setIsSubmitting(false);
  };

  return (
    <Container>
      {!gameStarted && (
        <Button onClick={startGame} size="lg" block>
          Start Game
        </Button>
      )}
      {isLoading && (
        <img
          src="/ZNeT.gif"
          alt="Loading..."
          className="img-fluid game-image soft-corners image-gradient"
        />
      )}
      {gameStarted && !isLoading && (
        <Row>
          {imageUrl && (
            <Col md={9}>
              <img
                src={imageUrl}
                alt="game"
                className="img-fluid game-image soft-corners image-gradient"
              />
            </Col>
          )}
          <Col md={3}>
            <form onSubmit={submitAnswer}>
              <FormControl
                as="textarea"
                rows={10}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="What do you see in this picture?" 
              />
              <Button disabled={isSubmitting} type="submit">Get My Score!</Button>
            </form>
            {isSubmitting ? <img src="/ZNeT.gif" alt="Loading..." /> : score !== null && <p>Score: {score}</p>}
            {message && <p>{message}</p>}
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default GameComponent;
