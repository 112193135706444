import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
function NavigationBar() {
    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand href="/">AI Games!</Navbar.Brand>
            <Nav className="mr-auto">
                <Nav.Link as={NavLink} to="/game/prompt">Guess the Prompt!</Nav.Link>
                <Nav.Link as={NavLink} to="/game/rhyme">Guess the Rhyme!</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
                <Nav.Link href="#about">About</Nav.Link>
            </Nav>
        </Navbar>
    );
}

export default NavigationBar;