import React from 'react';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';

function GameList() {
    const games = [
        { icon: '/icons/prompt.png', title: 'Guess the Prompt!', description: 'AI will generate a prompt to request a new image. We show you the image and you have to guess what the AI asked for! ', link: '/game/prompt' },
        { icon: '/icons/rhyme.png', title: 'Guess the Rhyme', description: 'You will see an AI generated image and have to guess which poem or song lyric was used to generate it!', link: '/game/rhyme' },
    ];


    return (
        <Container>
            <Row className="justify-content-md-center">
                {games.map((game, index) => (
                    <Col key={index} xs={12} md={4} className="mb-5 game-list-border">
                        <h2>{game.title}</h2>
                        <Image src={game.icon} alt={game.title} width="196" height="196" rounded className="image-gradient" /> 
                        <p>{game.description}</p>
                        <Button variant="primary" href={game.link}>Go to game</Button>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default GameList;